import { useEffect, useRef, useState } from 'react';
import { useSwiper } from 'swiper/react';
import PropTypes from 'prop-types';
import { CardComponent } from '../../atoms/card';
import { colors } from '../../quarks/theme';
import { AccordionComponent } from '../../atoms/accordion';
import { DrugCardStyle } from './style';
import styled from 'styled-components';
import { FavoriteDisabledIcon, FavoriteIcon } from '../../quarks/icons';

const FavoriteIconContainer = styled.div`
  position: absolute;
  right: 16px;
  top: 0px;
  margin-top: -6px;
  cursor: pointer;

  transition: all 0.2s;

  &:hover {
    filter: brightness(1.1);
  }
`;

export const DrugCardComponent = (props) => {
  const swiper = useSwiper();
  const ref = useRef(null);
  const bodyRef = useRef(null);
  const [headerHeight, setHeaderHeight] = useState(null);

  useEffect(() => {
    setHeaderHeight(ref.current?.children[0]?.offsetHeight);
  }, [ref.current?.children[0]?.offsetHeight]);

  function scrollAccordionIntoView(scrollTo) {
    bodyRef.current.scrollTo({
      top: scrollTo - headerHeight,
      behavior: 'smooth',
    });
  }

  function scrollSiblings(e) {
    const targetClassList = e?.target.closest('.drugAccordion').classList;
    const drugCards = document.querySelectorAll(`.drugCard__content`);
    drugCards?.forEach((drugCard) => {
      const drugHeader = drugCard?.previousElementSibling.clientHeight;
      const targetedSection = drugCard?.querySelector(
        `.${targetClassList?.item(targetClassList?.length - 1)}.drugAccordion`
      );
      if (drugCard.dataset.currentActivePrinciple === 'false') {
        drugCard?.scrollTo({
          top: targetedSection?.offsetTop - drugHeader,
          behavior: 'smooth',
        });
      }
    });
  }

  if (props.isCurrent && props.slideTo) {
    swiper.slideTo(props.slideTo);
    props.setSlideTo(null);
  }

  function handlePressArrowDown() {
    document.addEventListener('keyup', (e) => {
      if (e.key === 'ArrowDown') {
        document
          .querySelector('[data-current-active-principle="true"]')
          ?.focus();
      }
    });
  }
  useEffect(() => {
    handlePressArrowDown();
  }, []);

  // if(props.isCurrent) {
  //   bodyRef?.current?.focus()
  // }

  function handleClick() {
    if (props.isCurrent) {
      return;
    }
    if (props.isPrev) {
      swiper.slidePrev();
      return;
    }
    if (props.isNext) {
      swiper.slideNext();
      return;
    }
  }

  const [isFavLoading, setIsFavLoading] = useState(false);

  async function handleFavAdd() {
    setIsFavLoading(true);
    await props?.addFavorite({
      title: props?.title,
      legend: props?.tg_title,
      apId: props?.id,
      tgId: props?.tg_id,
      type: 'active_principle',
    });
    setIsFavLoading(false);
  }
  async function handleFavRemove() {
    setIsFavLoading(true);
    await props?.removeFavorite({
      title: props?.title,
      legend: props?.tg_title,
      apId: props?.id,
      tgId: props?.tg_id,
      type: 'active_principle',
    });
    setIsFavLoading(false);
  }

  return (
    <CardComponent
      backgroundColor={colors.black400}
      padding="0"
      className="drug_card"
    >
      {props.isPaid ? null : props?.isFavorited ? (
        <FavoriteIconContainer
          onClick={() => {
            if (isFavLoading) return;

            handleFavRemove();
          }}
        >
          <FavoriteIcon width="30" heigth="30" />
        </FavoriteIconContainer>
      ) : (
        <FavoriteIconContainer
          onClick={() => {
            if (isFavLoading) return;

            handleFavAdd();
          }}
        >
          <FavoriteDisabledIcon width="30" heigth="30" />
        </FavoriteIconContainer>
      )}
      <DrugCardStyle
        onClick={handleClick}
        ref={ref}
        activePrincipleTitleHeight={headerHeight}
        {...props}
      >
        <h2 className="drugsCard_title">{props.title}</h2>
        <div
          className="drugCard__content"
          ref={bodyRef}
          data-current-active-principle={props.isCurrent}
          tabIndex="-1"
        >
          {props.sections &&
            props.sections.map((section, index) => (
              <AccordionComponent
                isDisabled={
                  props.isPaid
                    ? false
                    : !section.content ||
                      section.content[0]?.props?.children === false
                }
                isPaid={props.isPaid}
                title={section.title}
                className={section.type}
                item_type={'Drogas'}
                active_principle_title={props.title}
                therapeutic_group_title={props.tg_title}
                id={section.id}
                key={index}
                isOpen={
                  section.content &&
                  section?.isOpen &&
                  section.content[0]?.props?.children !== false
                }
                onOpenCloseCallback={section.onOpenCloseCallback}
                scrollSiblings={scrollSiblings}
                parentRef={bodyRef}
                scrollAccordionIntoView={scrollAccordionIntoView}
                isCurrent={props.isCurrent}
              >
                {section.content}
              </AccordionComponent>
            ))}
        </div>
      </DrugCardStyle>
    </CardComponent>
  );
};

DrugCardComponent.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      type: PropTypes.string,
      content: PropTypes.node,
    })
  ),
  title: PropTypes.string,
};
