import PropTypes from 'prop-types';
import { usePaywall } from '../../../services/hooks/handleRedirectToPaywall';
import { BBLock, FavoriteDisabledIcon } from '../../quarks/icons';
import { colors } from '../../quarks/theme';
import { RoutineCardStyle } from './style';
import { useTrack } from '../../../services/hooks/useTrack';
import styled from 'styled-components';
import { FavoriteIcon } from '../../quarks/icons';
import { useState } from 'react';

const FavoriteIconContainer = styled.div`
  position: absolute;
  z-index: 50;
  right: 0px;
  top: 0px;
  margin-top: -7px;
  cursor: pointer;

  transition: all 0.2s;

  &:hover {
    filter: brightness(1.1);
  }
`;

export const RoutineCardComponent = (props) => {
  const { redirectToPaywall } = usePaywall();
  const { trackEvent } = useTrack();

  const [isFavLoading, setIsFavLoading] = useState(false);



  async function handleFavAdd() {
    setIsFavLoading(true);
    await props?.addFavorite({
      title: props?.card_title,
      legend: props?.routine_title,
      routineId: props.routineId,
      cardId: props.cardId,
      type: 'card',
    });
    setIsFavLoading(false);
  }
  async function handleFavRemove() {
    setIsFavLoading(true);
    await props?.removeFavorite({
      title: props?.card_title,
      legend: props?.routine_title,
      routineId: props.routineId,
      cardId: props.cardId,
      type: 'card',
    })
    setIsFavLoading(false);
  }

  return (
    <RoutineCardStyle
      isResumeCard={props.isResumeCard}
      color={props.color || colors.softBlack}
      {...props}
    >
      <h3 className="card__title">
        {props.cardTitle}
        {props.isPaid && (
          <button
            className="paid__button"
            type="button"
            onClick={() => {
              trackEvent('Clicou no cadeado', {
                Origem: 'Rotinas',
                Rotina: props?.routine_title,
                Tab: props?.tab_title,
                Card: props?.card_title,
              });
              redirectToPaywall();
            }}
          >
            <BBLock />
          </button>
        )}
        {props.isBlocked ? null : props?.isFavorited ? (
          <FavoriteIconContainer
            onClick={() => {
              if (isFavLoading) return;

              handleFavRemove();
            }}
          >
            <FavoriteIcon />
          </FavoriteIconContainer>
        ) : (
          <FavoriteIconContainer
            onClick={() => {
              if (isFavLoading) return;

              handleFavAdd();
            }}
          >
            <FavoriteDisabledIcon />
          </FavoriteIconContainer>
        )}
      </h3>
      {!props.isPaid && <div className="card__body">{props.children}</div>}
    </RoutineCardStyle>
  );
};

RoutineCardComponent.propTypes = {
  cardTitle: PropTypes.string,
  children: PropTypes.node,
  color: PropTypes.string,
};

RoutineCardComponent.defaultProps = {
  color: colors.softBlack,
};
